<template>
  <app-layout class="bg-background">
    <div class="container mx-auto">
      <app-loading-page
        :text="$t('general.loading')"
        :loading="submitLoading"
        class="w-40 h-20 m-auto rounded-md shadow-md bg-transparent"
      />
      <!-- Download CSV Modal -->
      <app-modal
        :show="show_modal_download_csv"
        @close="show_modal_download_csv = false"
      >
        <template v-slot:title>
          {{ $t("sales.download_csv_title") }}
        </template>
        <template v-slot:body>
          <p class="text-sm text-gray-500">
            {{ $t("sales.download_csv_description") }}
          </p>
        </template>
        <template v-slot:action>
          <app-button
            @click="downloadCsvListBusiness"
            :showf70Icon="false"
            type="button"
            class="sm:ml-3 sm:w-auto"
          >
            {{ $t("general.download_csv") }}
          </app-button>
        </template>
      </app-modal>
      <app-table
        :apiResponse="apiPaginationResponse.data"
        :showEmptyButton="false"
        :loading="apiPaginationLoading"
        @params-changed="params_changed"
        @pagination-per-page-selected="per_page_changed"
        :filters="[
          {
            key: 'filter[name]',
            type: 'text',
            placeholder: $t('business.table.header_businss_name'),
          },
          {
            key: 'filter[merchant_type]',
            type: 'select',
            placeholder: $t('business.table.header_merchant_type'),
            items: merchant_types,
          },
          {
            key: 'filter[business_status_id]',
            type: 'select',
            placeholder: $t('business.table.header_status'),
            items: [
              {
                id: '1',
                name: 'Created',
              },
              {
                id: '2',
                name: 'Submitted',
              },
              {
                id: '3',
                name: 'Approved',
              },
              {
                id: '4',
                name: 'Rejected',
              },
              {
                id: '5',
                name: 'Amended',
              },
            ],
          },
        ]"
        @on-item-click="onItemClick"
      >
        <template v-slot:button>
          <app-button
            @click="show_modal_download_csv = true"
            :showf70Icon="false"
            type="button"
            class="sm:ml-3 sm:w-auto"
          >
            {{ $t("payout.modal.download_csv_button") }}
          </app-button>
        </template>
        <template v-slot:header>
          <td>
            <b>{{ $t("business.table.header_businss_name") }}</b>
          </td>
          <td>
            <b>{{ $t("business.table.header_merchant_type") }}</b>
          </td>
          <td>
            <b>{{ $t("business.table.header_no_of_transaction") }}</b>
          </td>
          <td>
            <b>{{ $t("business.table.header_transaction_volume") }}</b>
          </td>
          <td>
            <b>{{ $t("business.table.header_total_profit") }}</b>
          </td>
          <td class="flex justify-center">
            <b>{{ $t("business.table.header_status") }}</b>
          </td>
        </template>
        <template v-slot:body="data">
          <td>
            <p>
              {{ data.model?.name }}
            </p>
          </td>
          <td class="capitalize">
            <p v-html="getMasterMerchant(data.model).split('_').join(' ')" />
          </td>
          <td>
            <p>{{ data.model?.payment_summary?.no_of_transaction ?? "-" }}</p>
          </td>
          <td>
            <p>
              {{
                $formats.currency(
                  data.model?.payment_summary?.currency,
                  data.model?.payment_summary?.transaction_volume ?? 0.0
                )
              }}
            </p>
          </td>
          <td>
            <p>
              {{
                $formats.currency(
                  data.model?.payment_summary?.currency,
                  data.model?.payment_summary?.total_profit_amount ?? 0.0
                )
              }}
            </p>
          </td>
          <td>
            <app-badge :status="getStatus(data.model?.business_status_id)">
              {{ data.model?.status?.name ?? "-" }}
            </app-badge>
          </td>
        </template>
      </app-table>
    </div>
  </app-layout>
</template>

<script>
import MERCHANT_TYPE from "@/utils/const/merchant_type";
export default {
  data() {
    return {
      queryParams: "",
      show_modal_download_csv: false,
      submitLoading: false,
    };
  },
  created() {
    this.fetchListBusiness();
  },
  computed: {
    apiPaginationResponse() {
      return this.$store.getters["businessStore/apiPaginationResponse"];
    },
    merchant_types() {
      return this.$store.getters["businessStore/merchant_types"];
    },
    apiPaginationLoading() {
      return this.$store.getters["businessStore/apiPaginationLoading"];
    },
  },
  mounted() {
    this.fetchListBusiness();

    this.$store.dispatch("businessStore/getAllMerchantType");
  },
  methods: {
    fetchListBusiness() {
      this.$store.dispatch("businessStore/getListBusinessForSales", this.queryParams);
    },
    getStatus(status_id) {
      switch (status_id) {
        case 1:
          return "light";
        case 2:
          return "light";
        case 3:
          return "success";
        case 4:
          return "error";
        case 5:
          return "warning";
        default:
          return "light";
      }
    },
    params_changed(filters, queryParams) {
      this.queryParams = queryParams;
      this.fetchListBusiness();
    },
    per_page_changed(per_page) {
      this.filters.per_page = per_page;
      this.fetchListBusiness();
    },
    getMasterMerchant(masterMerchant) {
      if (masterMerchant.master_business_type == null) {
        return MERCHANT_TYPE.MERCHANT;
      } else if (masterMerchant.master_business_type == "master_merchant") {
        return MERCHANT_TYPE.MASTER_MERCHANT;
      } else if (masterMerchant.master_business_type == "sub_merchant") {
        if (masterMerchant.master_business_id != null)
          return (
            MERCHANT_TYPE.SUB_MERCHANT +
            " ( " +
            masterMerchant.master_business.name +
            " )"
          );
      }
      return MERCHANT_TYPE.SUB_MERCHANT;
    },
    async downloadCsvListBusiness() {
      this.show_modal_download_csv = false;
      this.submitLoading = true;
      const csv_url = await this.$store.dispatch(
        "businessStore/getSalesListBusinessExports",
        this.queryParams
      );

      this.submitLoading = false;

      if (!this.$lodash.isEmpty(csv_url.data)) {
        window.location.href = csv_url.data;
        this.fetchListBusiness();
      }
    },
  },
};
</script>